<template>

  <div class="container
              page-container
              is-flex
              is-flex-wrap-wrap
              is-justify-content-center
              is-align-items-center">

    <div style="width: 35%; min-width: 300px;">

      <div class="mb-6">
        <p class="has-text-grey
                  has-text-centered
                  is-size-5
                  is-size-6-mobile mb-1">
          Réinitialisez votre mot de passe
        </p>
      </div>

      <b-field label="Email"
               :type="{ 'is-danger': $v.email.$error }"
               :message="[
                 { 'Email non valide': $v.email.$error },
               ]"
               v-show="!showSuccessMessage">
        <b-input type="email"
                 ref="emailInput"
                 v-model.trim="$v.email.$model"
                 @keyup.enter.native="sendResetEmail"/>
      </b-field>

      <b-notification
        type="is-dark"
        aria-close-label="Fermer message d'erreur"
        role="alert"
        closable
        class="mt-4"
        v-model="active">
        {{ error }}
      </b-notification>

      <div v-if="!showSuccessMessage" class="mb-6">
        <router-link to="/signin">Je me souviens de mon mot de passe</router-link>
      </div>

      <b-button type="is-primary"
                :disabled="$v.$invalid"
                expanded
                @click="sendResetEmail"
                v-if="!showSuccessMessage"
                class="is-primary mb-4"
                icon-left="send">
        Envoyer
      </b-button>

      <b-notification
        type="is-success"
        aria-close-label="Fermer message d'erreur"
        role="alert"
        :closable="false"
        class="mt-4"
        has-icon
        v-model="showSuccessMessage">
        <p>Email vérifié.</p>
        <p>Un email vient de vous être envoyé.</p>
        <p>Veuillez consulter votre boite mail et cliquer sur le lien d'activation.</p>
      </b-notification>

      <b-button type="is-primary"
                tag="router-link"
                expanded
                to="/signin"
                v-if="showSuccessMessage"
                icon-left="chevron-left">
        Retourner à la page d'authentification
      </b-button>

    </div>

  </div>

</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
  data: () => ({
    email: '',
    showSuccessMessage: false,
    error: null,
    active: false,
  }),
  validations: {
    email: {
      required,
      email,
    },
  },
  mounted() {
    this.$refs.emailInput.focus();
  },
  methods: {
    async sendResetEmail() {
      if (!this.disabled) {
        const loadingComponent = this.$buefy.loading.open({
          container: null,
        });
        const payload = { email: this.email };

        this.active = false;
        try {
          await this.$http.post(`${process.env.VUE_APP_API_URL}/auth/password/forgot`, payload);
          this.showSuccessMessage = true;
        } catch (err) {
          const { status } = err.response;
          let message = '';

          switch (status) {
            case 400:
              message = 'Email invalide.';
              break;
            case 404:
              message = 'Aucun utilisateur ne correspond à cette adresse email.';
              break;
            case 425:
              message = 'Email déjà envoyé, veuillez vérifier votre dossiers spam/courriers indésirables.';
              break;
            case 429:
              message = 'Nombre d\'essais autorisés dépassé, veuillez réessayer dans une heure.';
              break;
            default:
              message = 'Une erreur est survenue, nous n\'avons malheureusement pas pu répondre à votre demande.';
          }
          this.error = message;
          this.active = true;
        } finally { loadingComponent.close(); }
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .page-container {
    min-height: calc(100vh - 162px);
  }
  ::v-deep label {
    font-weight: 400 !important;
  }
</style>